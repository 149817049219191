// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-giveaways-tsx": () => import("./../../../src/pages/giveaways.tsx" /* webpackChunkName: "component---src-pages-giveaways-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-series-tsx": () => import("./../../../src/pages/series.tsx" /* webpackChunkName: "component---src-pages-series-tsx" */),
  "component---src-pages-signup-thankyou-tsx": () => import("./../../../src/pages/signup-thankyou.tsx" /* webpackChunkName: "component---src-pages-signup-thankyou-tsx" */),
  "component---src-pages-techniques-tsx": () => import("./../../../src/pages/techniques.tsx" /* webpackChunkName: "component---src-pages-techniques-tsx" */),
  "component---src-templates-article-list-article-list-tsx": () => import("./../../../src/templates/article_list/article_list.tsx" /* webpackChunkName: "component---src-templates-article-list-article-list-tsx" */),
  "component---src-templates-articles-article-tsx": () => import("./../../../src/templates/articles/article.tsx" /* webpackChunkName: "component---src-templates-articles-article-tsx" */),
  "component---src-templates-contributors-contributor-tsx": () => import("./../../../src/templates/contributors/contributor.tsx" /* webpackChunkName: "component---src-templates-contributors-contributor-tsx" */),
  "component---src-templates-index-index-tsx": () => import("./../../../src/templates/index/index.tsx" /* webpackChunkName: "component---src-templates-index-index-tsx" */),
  "component---src-templates-partners-partner-tsx": () => import("./../../../src/templates/partners/partner.tsx" /* webpackChunkName: "component---src-templates-partners-partner-tsx" */),
  "component---src-templates-series-series-tsx": () => import("./../../../src/templates/series/series.tsx" /* webpackChunkName: "component---src-templates-series-series-tsx" */),
  "component---src-templates-technique-technique-tsx": () => import("./../../../src/templates/technique/technique.tsx" /* webpackChunkName: "component---src-templates-technique-technique-tsx" */)
}

